// This is the webpack entry point
// Refrain from putting actual code in here and just import the modules you need;

// import VendorScriptLoader from './utility/vendor-script-loader';

// Google Maps :: when loading the Google Maps js API a callback is required to be passed, but since
// we are already firing an event when the script is loaded, there's no need for this callback.
// We define it just to avoid the console error.
window.initMap = () => {};

/**
 * Code-splitting :: Blocks
 *
 * Manages blocks that should load dynamically (only when they exist in the DOM)
 * using Webpack's code-splitting feature.
 */
function registerBlocks() {
  if ( document.querySelector('.js-cta-form') ) {
    import('~scripts/blocks/cta/cta-form');
  }
}

/**
 * Code-splitting :: Components
 *
 * Manages components that should load dynamically (only when they exist in the DOM)
 * using Webpack's code-splitting feature.
 */
function registerComponents() {

  // if ( document.querySelector('.js-example-component') ) {
  //   import('~scripts/components/example');
  // }

  // Form :: custom choice fields
  // if ( document.querySelectorAll('.form-style select').length ) {
  //   // Loads component only after vendor file is loaded
  //   document.addEventListener('vendor-choices:loaded', () => {
  //     import('~scripts/components/form/form-custom-select-styles').then(component => {
  //       component.default.init('.form-style');
  //     });
  //   });
  //
  //   VendorScriptLoader.outputVendorScriptTag('vendor-choices');
  // }
}


/**
 * DOMContentLoaded events should be listened to on this file only.
 */
document.addEventListener('DOMContentLoaded', registerBlocks);
document.addEventListener('DOMContentLoaded', registerComponents);

/**
 * Load common components here
 */
// document.addEventListener('DOMContentLoaded', () => {
//
// });
